<template>
    <sidebar-general
        ref="sidebarCrearActividad"
        title="Crear actividad"
        aceptar="Crear"
    >
        <div class="p-1">
            <div>
                <label for="titulo">Título</label>
                <b-form-input id="titulo"></b-form-input>
            </div>
            <div class="mt-1">
                <label for="descripcion">Descripción</label>
                <b-form-input id="descripcion"></b-form-input>
            </div>
            <div class="mt-1">
                <label for="Fecha">Fecha</label>
                <b-form-datepicker></b-form-datepicker>
            </div>
            <div class="mt-1">
                <label for="Fecha">Fecha</label>
                <b-form-datepicker></b-form-datepicker>
            </div>
        </div>
    </sidebar-general>
</template>
<script>
import { ref } from "@vue/composition-api";
export default {
    setup(props, context) {
        function toggle() {
            context.refs.sidebarCrearActividad.toggle();
        }

        return { toggle };
    },
};
</script>
